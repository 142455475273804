<template>
  <v-container grid-list-lg class="ap-table-header">
    <v-layout row>
      <v-flex v-if="appSelect" xs8>
        <TenantApplicationSelect
          @onChange="onAppChange"
        />
      </v-flex>
      <v-flex v-else xs8>
        <v-spacer></v-spacer>
      </v-flex>
      <v-flex xs4>
        <v-text-field
          class="ap-table-header-item"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          v-model="search"
          @keyup="onChange"
        />
      </v-flex>
      <v-flex xs2 class="text-xs-center">
        <v-icon
          @click="refresh"
          class="refresh"
        >refresh</v-icon>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'BaseTableHeader',
  props: {
    appSelect: Boolean,
    appSelectValue: String,
    tenantApps: Array,
  },
  data: () => ({
    search: '',
  }),
  components: {
    TenantApplicationSelect: () => import('@/components/workspace/TenantApplicationSelect'),
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  methods: {
    onChange(event) {
      this.$emit('searchChange', event.currentTarget.value);
    },
    onAppChange(app) {
      this.$emit('appChange', app);
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>
